import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Margin from "../components/margin"
import Leading from "../components/leading"
import SectionLine from "../components/section-line"
import TwitterLink from "../components/twitter-link"

import { FaTwitter, FaTelegram, FaFacebook } from "react-icons/fa"
import { BsThreeDotsVertical } from "react-icons/bs"


const CampaignPage: React.FC = () => (
  <Layout>
    <Seo title="Campaign" />

    <div className="container">
      <Leading main="継続委任キャンペーン" sub=""/>
      <Margin height="2.0rem"/>
      <div className="campaign-title-bg">
        <strong className="campaign-title">
          <span style={{ fontSize: "120%" }}>OPEN記念！</span><br />
          <span style={{ fontSize: "150%" }}>総額&nbsp;3,900&thinsp;ADA</span><br />
          <span style={{ fontSize: "120%" }}>プレゼント！</span>
        </strong>
      </div>
      <Margin height="2.0rem"/>
      <p style={{ color: "red", fontSize: "120%", fontWeight: "bold", textAlign: "center" }}>
        このキャンペーンは終了いたしました。
      </p>
      <Margin height="2.0rem"/>
      <p style={{ color: "navy", fontSize: "110%", fontWeight: "bold", textAlign: "center" }}>
        期間中に&nbsp;<span className="mono">1,000</span>&thinsp;<span className="mono">ADA</span>&nbsp;以上を<br />
        10&thinsp;エポック&thinsp;継続して委任された方へ<br />
        最大&nbsp;<span className="mono">1,000</span>&thinsp;<span className="mono">ADA</span>&nbsp;プレゼント！
      </p>
      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>キャンペーン報酬</strong>
        <div>
          10&thinsp;エポックの平均委任額に対し<br />
          <span className="mono">1,000</span>&thinsp;<span className="mono">ADA</span>&nbsp;につき&nbsp;<span className="mono">1</span>&thinsp;<span className="mono">ADA</span>
        </div>
        <Margin height="1.0rem"/>
        <div style={{ background: 'ghostwhite', maxWidth: '300px', margin: 'auto', padding: "0.5rem", borderRadius: '10px' }}>
          <table style={{ textAlign: "left", maxWidth: "300px", margin: "auto" }}>
            <thead style={{ padding: "1rem", borderBottom: "1px solid slategray" }}>
              <tr>
                <td style={{ textAlign: "center"}}>委任額</td>
                <td style={{ width: "2rem" }}></td>
                <td style={{ textAlign: "center"}}>報酬額</td>
              </tr>
            </thead>
            <tbody className="mono">
              <tr>
                <td style={{ textAlign: "right" }}>1,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>1 ADA</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>2,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>2 ADA</td>
              </tr>
               <tr>
                <td style={{ textAlign: "right" }}>3,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>3 ADA</td>
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "center", color: "lightslategray"}}><BsThreeDotsVertical style={{ display: "inline", marginBottom: "0.2rem" }}/></td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>10,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>10 ADA</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>20,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>20 ADA</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>30,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>30 ADA</td>
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "center", color: "lightslategray" }}><BsThreeDotsVertical style={{ display: "inline", marginBottom: "0.2rem" }}/></td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>100,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>100 ADA</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>200,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>200 ADA</td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>300,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>300 ADA</td>
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "center", color: "lightslategray" }}><BsThreeDotsVertical style={{ display: "inline", marginBottom: "0.2rem" }}/></td>
              </tr>
              <tr>
                <td style={{ textAlign: "right" }}>1,000,000 ADA</td>
                <td></td>
                <td style={{ textAlign: "right" }}>1,000 ADA</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Margin height="1.0rem"/>
        <div className="container-text" style={{ background: 'cornsilk', margin: 'auto' }}>
          例として&nbsp;<span className="mono">123,900</span>&thinsp;<span className="mono">ADA</span>&nbsp;ご委任の場合は
          &thinsp;<span className="mono">123</span>&thinsp;<span className="mono">ADA</span>&nbsp;のプレゼントとなります。
        </div>
        <Margin height="1.0rem"/>
        <div className="box" style={{ background: 'ghostwhite', padding: '0.5rem', maxWidth: '500px' }}>
          <div className="container-text">
            <small>&middot;報酬上限額:<br />
            &emsp;1名につき&ensp;…&ensp;最大&thinsp;<span className="mono">1,000</span>&thinsp;<span className="mono">ADA</span><br />
            &emsp;キャンペーン全体&ensp;…&ensp;最大&thinsp;<span className="mono">3,900</span>&thinsp;<span className="mono">ADA</span><br />
            </small>
            <small>&middot;委任の先着となります。</small>
            <br />
            <small>&middot;なくなり次第配布終了となります。</small>
            <br />
            <small>&middot;通常のステーキング報酬も発生します。</small>
            <br />
            <small>&middot;配布は委任から10エポック経過後になります。</small>
            <br />
            <small>&middot;委任額は10エポックの平均を用いて計算します。</small>
            <br />
            <small>&middot;報酬獲得後も続けて参加いただくことが可能です。</small>
          </div>
        </div>
      </div>

      <Margin height="1rem"/>

      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>キャンペーン期間</strong>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <span style={{ color:'red' }}>期間延長中！</span><br />
            エポック&thinsp;<span className="mono">324</span>&thinsp;～&thinsp;<span className="mono" style={{ textDecoration: 'line-through' }}>333</span>&thinsp;<span className="font-mono" style={{ color:'red' }}>393</span><br />
            に開始された委任が対象です。
          </div>
        </div>
      </div>

      <Margin height="1rem" />

      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>参加方法</strong>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            対象期間中に下記を完了
            <Margin height="0.5rem"/>
            <div className="box" style={{ background: 'ghostwhite', padding: '0.5rem', maxWidth: '500px' }}>
              <div className="container-text">
                <span>&middot;キャンペーン内容と注意事項を確認</span>
                <br />
                <span>&middot;<span className="mono">1,000</span>&thinsp;<span className="mono">ADA</span>&nbsp;以上を&nbsp;<a href="https://adapools.org/pool/e8f538755c14d1e7f1274b46b5f292ba0c247149a8ba7b9eb410eb1a" target="_blank"><span className="mono">1STEP</span></a>&nbsp;へ委任開始</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Margin height="1rem" />

      <div className="box" style={{ background: 'lavender' }}>
        <strong style={{ marginBottom: "1rem" }}>注意事項</strong>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <div className="box" style={{ background: 'ghostwhite', padding: '0.5rem', maxWidth: '560px' }}>
              <div className="container-text">
                <small>&middot;期間内に委任を開始された方のみが対象となります。</small>
                <br />
                <small>&middot;委任額順ではなく先着順となります。</small>
                <br />
                <small>&middot;透明性のため、ステークアドレスと平均委任額の一覧をサイトに掲載する場合があります。</small>
                <br />
                <small>&middot;キャンペーン残額が報酬額に満たない場合は残額分のみのプレゼントとなります。</small>
                <br />
                <small>&middot;キャンペーン残額がなくなった時点でキャンペーンは終了となります。</small>
                <br />
                <small>&middot;残額によっては委任開始の対象期間を延長する場合があります。</small>
                <br />
                <small>&middot;報酬の納税については、お住いの地域の税理士や税務署などへお問い合わせください。</small>
                <br />
                <small>&middot;途中で2エポック以上委任されない状態が続いた場合は辞退と判定致します。</small>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>

    <Margin height="0.5rem"/>
    <SectionLine />

    <div className="container">
      <p>
        <Link to="/reward" style={{ display: "inline" }}>
          <strong>キャンペーン報酬確認</strong>
        </Link>
      </p>
      <p>
        <Link to="/" style={{ display: "inline" }}>
          <strong>トップページ - Top Page</strong>
        </Link>
      </p>
    </div>

  </Layout>
)

export default CampaignPage

